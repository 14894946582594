import { UserRole, UserStatus } from 'app/shared/enums';
import { Client } from './Client';
import { UserCompanyRole } from '@cartwheel/web-components';
import { UUID } from 'angular2-uuid';
import { CustomFieldByName } from './custom-fields';
import { EnterpriseUser } from './enterprise-user';

export class CompanyUser {

  userName: string;
  email: string;
  userId: string;
  companyId: string;
  firstName: string;
  lastName: string;
  companyRole: UserCompanyRole;
  status: UserStatus;
  earnings?: number;

  constructor(data?: CompanyUser) {
    if (data.userName) {
      this.userName = data.userName;
    }
    if (data.email) {
      this.email = data.email;
    }
    if (data.companyRole) {
      this.companyRole = data.companyRole;
    }
    if (data.userId) {
      this.userId = data.userId;
    }
    if (data.companyId) {
      this.companyId = data.companyId;
    }
    if (data.firstName) {
      this.firstName = data.firstName;
    }
    if (data.lastName) {
      this.lastName = data.lastName;
    }
    if (data.status !== null && data.status !== undefined) {
      this.status = data.status;
    }
    if(data.earnings !== null && data.earnings !== undefined) {
      this.earnings = data.earnings;
    }
  }
}

export class AddUserRequest {
  user: EnterpriseUser;
  customFields?: CustomFieldByName[]
  constructor(User: EnterpriseUser, CustomFields?: CustomFieldByName[]) {
    this.user = User;
    if (CustomFields) {
      this.customFields = CustomFields;
    }
  }
}

export class CompanyUserAndCompanyId {
  companyId: string;
  companyUser: CompanyUser;
}

export class TeamUserListModel extends CompanyUser {
  clients: Client[];
  earnings: number;
  billedHours: number;
  currentEarnings: number;

  constructor(data?: TeamUserListModel) {
    super(data);
    if (data.clients) {
      this.clients = data.clients;
    }

    if (data.earnings !== undefined) {
      this.earnings = data.earnings;
    }

    if (data.billedHours) {
      this.billedHours = data.billedHours;
    }

    if(data.currentEarnings !== null && data.currentEarnings !== undefined) {
      this.currentEarnings = data.currentEarnings;
    }
  }
}

export class CompanyUserPagedRequest {
  limit?: number = 8;
  page?: number = 1;
  companyId?: string | UUID;
  createdAtStartDate?: Date;
  createdAtEndDate?: Date;
  hourlyRateMin?: number;
  hourlyRateMax?: number;
  otHourlyRateMin?: number;
  otHourlyRateMax?: number;
  userRole?: UserRole;
  status?: UserStatus
  userNames?: string[];
  sortByColumn?: string;
  isSortDescending?: boolean;
  keyword?: string;

  constructor(data?: any) {
    if (data?.limit !== null && data?.limit !== undefined) {
      this.limit = data.limit;
    }
    if (data?.page !== null && data?.page !== undefined) {
      this.page = data.page;
    }
    if (data?.companyId) {
      this.companyId = data.companyId;
    }
    if (data?.createdAtStartDate) {
      this.createdAtStartDate = data.createdAtStartDate;
    }
    if (data?.createdAtEndDate) {
      this.createdAtEndDate = data.createdAtEndDate;
    }
    if (data?.hourlyRateMin !== null && data?.hourlyRateMin !== undefined) {
      this.hourlyRateMin = data.amountMin;
    }
    if (data?.hourlyRateMax !== null && data?.hourlyRateMax !== undefined) {
      this.hourlyRateMax = data.hourlyRateMax;
    }
    if (data?.hourlyRateMin !== null && data?.hourlyRateMin !== undefined) {
      this.hourlyRateMin = data.amountMin;
    }
    if (data?.otHourlyRateMin !== null && data?.otHourlyRateMin !== undefined) {
      this.otHourlyRateMin = data.otHourlyRateMin;
    }
    if (data?.otHourlyRateMax !== null && data?.otHourlyRateMax !== undefined) {
      this.otHourlyRateMax = data.otHourlyRateMax;
    }
    if (data?.userRole !== null && data?.userRole !== undefined) {
      this.userRole = data.userRole;
    }
    if (data?.status !== null && data?.status !== undefined) {
      this.status = data.status;
    }
    if (data?.userNames) {
      this.userNames = data.userNames;
    }
    if (data?.sortByColumn) {
      this.sortByColumn = data.sortByColumn;
    }
    if (data?.isSortDescending !== undefined && data?.isSortDescending !== null) {
      this.isSortDescending = data?.isSortDescending;
    }
    if (data?.keyword !== null && data?.keyword !== undefined && data?.keyword !== '') {
      this.keyword = data.keyword;
    }
  }
}
